import { useAudioRecorder } from "react-audio-voice-recorder";
import { useState, useEffect } from "react";
import Player from "./player";
import axios from "axios";
import {auth} from "../../auth/firebase";
import {sendRequestWithToken} from "../../auth/security";

const Recorder = (props) => {
  const [hovered, setHovered] = useState(false);
  const [patientRecorded, setPatientRecorded] = useState(props.patientRecorded);
  const [actionRecorded, setActionRecorded] = useState(props.actionRecorded);
  const [fileUpload, setFileUpload] = useState(null);

  const formatRecordTime = (totalSeconds) => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    const formattedHours = String(hours).padStart(2, "0");
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(seconds).padStart(2, "0");

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  };

  const blobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const {
    startRecording,
    stopRecording,
    togglePauseResume,
    recordingBlob,
    isRecording,
    isPaused,
    recordingTime,
  } = useAudioRecorder();

  const resetAll = (e) => {
    props.handleStatus("reset");
    setFileUpload((fileUpload) => null);
  };

  useEffect(() => {
    if (!recordingBlob) return;
    handleAudio(recordingBlob);
    props.updateTab(props.currentTab, { statusUpdate: 'uploading' })
  }, [recordingBlob]);

  useEffect(() => {
    if (fileUpload === null) return;
    props.updateTab(props.currentTab, { statusUpdate: 'uploading' })

    handleAudio(fileUpload);
  }, [fileUpload]);

  useEffect(() => {
    props.updateTab(props.currentTab, { recordingStatus: 'recording', statusUpdate: 'recording' })
    if (!isRecording) props.updateTab(props.currentTab, { recordingStatus: 'idle', statusUpdate: 'not_recording' })
  }, [isRecording]);

  useEffect(() => {
    props.updateTab(props.currentTab, { recordingStatus: 'paused' })
    if (!isPaused) props.updateTab(props.currentTab, { recordingStatus: 'recording' })
  }, [isPaused]);
  

  useEffect(() => {
    props.handleStatus("not_recording");
    props.updateTab(props.currentTab, { recordingStatus: 'idle' })
    props.handleStartRecord({startRecording: startRecording});
    console.log(`Tab changed to ${props.tabs[props.currentTab].name}`);
  }, []);

  useEffect(() => {
    setPatientRecorded((patientRecorded) => props.patientRecorded);
  }, [props.patientRecorded]);

  useEffect(() => {
    setActionRecorded((actionRecorded) => props.actionRecorded);
  }, [props.actionRecorded]);

  useEffect(() => {
    if (props.tabs[props.currentTab].statusUpdate === 'retry_processing') {
      if (!props.tabs[props.currentTab].blob) return;
      props.updateTab(props.currentTab, { statusUpdate: 'uploading' })
      handleAudio(props.tabs[props.currentTab].blob);
    }
  }, [props.tabs]);




  const clickEvent = (e) => {
    isRecording ? stopRecording() : startRecording();
    props.userEvents(isRecording ? 'paused_clicked' : 'recording_clicked')
  }

  const handleAudio = async (blob) => {
    if (typeof(blob) === 'string') {
      blob = await fetch(blob).then(r => r.blob());
    }
    const base64String = await blobToBase64(blob);
    const generated_url = URL.createObjectURL(blob);

    props.updateTab(props.currentTab, { blob: generated_url })

    try {
      const body = {
        audio: base64String,
        sessionId: sessionStorage.getItem('sessionId'),
        mode: props.mode,
        type: props.tabs[props.currentTab].name,
        transcript: props.tabs[props.currentTab].data.transcript || null,
        mimeType: blob.type,
      };
      const contentType = "application/json";
      const url = `${process.env.REACT_APP_BASE_URL}/upload`;
      props.setShowModal(false);
      props.setSurveyType(props.tabs[props.currentTab].name);
      const response = await sendRequestWithToken(url, "POST", body, contentType);
      console.log("File uploaded successfully", response.data);
      props.updateTab(props.currentTab, { statusUpdate: 'done_uploading' })
    } catch (error) {
      props.updateTab(props.currentTab, { statusUpdate: 'error_uploading' })
      console.error("Error uploading file", error);
    }
    setFileUpload((fileUpload) => null);
  };

  return (
    <div
      id="recorder"
      className="bg-mozn-bg/50 border border-mozn-border border-t-0 w-1/3 max-w-[470px] flex flex-col overflow-auto"
    >
      <div className="h-32 w-full grow-0 flex justify-center items-center">
        <p className="text-mozn-text font-normal text-center self-center px-12 text-base">
          Use this tool to transcribe audio into text using text-to-speech
          technology, and generate an AI Summary of the objective
        </p>
      </div>
      <div className="h-[calc(100%-11rem)] w-full grow flex flex-col justify-start items-center">
        <div
            className="w-9/12 h-[460px] border-2 border-t-0 border-mozn-border bg-black/15 pb-2 flex flex-col justify-cneter items-center">
          <div id={"modes"} className={"w-full flex"}>
            <button
                className={`${props.mode !== "normal" ? "bg-gray-100/5 border-b-2 border-r-2 " : null} p-2 w-1/2 border-t-2 border-mozn-border pb-2 flex flex-col justify-cneter items-center`}
                onClick={(e) => {props.setMode('normal')}}
            >
              <p className="text-mozn-text font-normal text-center self-center text-lg"> Patient Conversation </p>
            </button>
            <button
                className={`${props.mode !== "dictation" ? "bg-gray-100/5 border-b-2 border-l-2 " : null} p-2 w-1/2 border-t-2 border-mozn-border pb-2 flex flex-col justify-cneter items-center`}
                onClick={(e) => {props.setMode('dictation')}}
            >
              <p className="text-mozn-text font-normal text-center self-center text-lg"> Doctor Only Scriber </p>
            </button>

          </div>
          <div
              id="tabs"
              className="w-full h-32 flex justify-center items-start py-6"
          >
            {
                Object.keys(props.tabs).length > 1 && Object.keys(props.tabs).map(tabId => (
                    <>
                      <div className="w-[30px] flex flex-col justify-start items-center">
                        <div
                            id={tabId}
                            style={
                              props.tabs[tabId].stepStatus === "current" || (props.currentTab === Object.keys(props.tabs).length && props.tabs[tabId].canRerecord)
                                  ? {backgroundImage: "url('tabActive.svg')"}
                                  : {backgroundImage: "url('tabInactive.svg')"}
                            }
                            className="h-[30px] w-[30px] mx-6 text-center text-mozn-text text-xs font-bold pt-2 flex justify-center"
                        >
                          {props.tabs[tabId].statusUpdate === 'done' ? (
                              <svg
                                  className="h-[8px] w-[11px] mt-1"
                                  width="11"
                                  height="8"
                                  viewBox="0 0 11 8"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                    d="M3.6297 7.9292C3.67475 7.97474 3.73614 7.99999 3.79951 7.99999C3.86387 8.00049 3.92575 7.97474 3.97081 7.9292L10.0682 1.83572C10.1138 1.79017 10.139 1.72878 10.139 1.66491C10.139 1.60105 10.1138 1.53966 10.0682 1.4946L8.63743 0.071292C8.59238 0.0257437 8.53049 0 8.46613 0C8.40226 0 8.34038 0.0257437 8.29532 0.071292L3.796 4.57061L1.84038 2.60612C1.79483 2.56057 1.73344 2.53483 1.66907 2.53483C1.60471 2.53483 1.54332 2.56057 1.49826 2.60612L0.0709028 4.03348C0.0253545 4.07854 -0.000389187 4.13992 0.000106372 4.20429C-0.00187384 4.27063 0.0238704 4.334 0.0709028 4.38054L3.6297 7.9292Z"
                                    fill="#23D5BE"
                                />
                              </svg>
                          ) : (
                              tabId
                          )}
                        </div>
                        <p
                            className={`${props.tabs[tabId].stepStatus === "current" ? "font-bold" : "font-normal"} pt-1 text-wrap text-sm text-center text-mozn-text`}
                        >
                          {props.tabs[tabId].name}
                        </p>
                      </div>
                      {Number(tabId) !== Object.keys(props.tabs).length ?
                          <hr className="min-w-12 w-12 max-w-12 h-0.5 mx-4 my-4 bg-mozn-text border-0 rounded"/> : null}
                    </>
                ))
            }
          </div>
          <div id="timer" className="h-20 pb-2">
            <p className="text-mozn-text font-bold text-center self-center text-5xl">
              {formatRecordTime(recordingTime)}
            </p>
          </div>
          <div id="status" className="h-12 py-2">
            <p className="text-mozn-text font-normal text-center self-center text-2xl">
              {props.tabs[props.currentTab].recordingStatus}
            </p>
          </div>
          <div
              id="controls"
              className="h-32 w-full max-w-96 py-2 flex justify-center items-center self-center"
          >
            <div className="w-full h-full flex justify-center items-center">
              <label htmlFor="file-upload" className="cursor-pointer">
                <svg
                    width="31"
                    height="32"
                    viewBox="0 0 31 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                      d="M16.8421 6.46858V22.6026C16.8421 23.5461 16.1013 24.2869 15.1579 24.2869C14.2145 24.2869 13.4737 23.5461 13.4737 22.6026V6.46858L7.91579 11.9921C7.24211 12.6658 6.19736 12.6658 5.52367 11.9921C4.84999 11.3184 4.84999 10.2737 5.52367 9.6L15.158 0L24.758 9.63436C25.4317 10.308 25.4317 11.3528 24.758 12.0265C24.0843 12.7002 23.0396 12.7002 22.3659 12.0265L16.8421 6.46858Z"
                      fill="#F4F8FC"
                  />
                  <path
                      d="M1.68421 21.8947C2.62764 21.8947 3.36842 22.6355 3.36842 23.5789V26.2737C3.36842 27.5868 4.41317 28.6316 5.72632 28.6316H24.5895C25.9026 28.6316 26.9474 27.5868 26.9474 26.2737V23.5789C26.9474 22.6355 27.6882 21.8947 28.6316 21.8947C29.575 21.8947 30.3158 22.6355 30.3158 23.5789V26.2737C30.3158 29.4395 27.7553 32 24.5895 32H5.72632C2.56054 32 0 29.4395 0 26.2737V23.5789C0 22.6355 0.740783 21.8947 1.68421 21.8947Z"
                      fill="#F4F8FC"
                  />
                </svg>
              </label>
              <input
                  id="file-upload"
                  type="file"
                  className="hidden"
                  onChange={(e) => setFileUpload(e.target.files[0])}
                  onClick={(e) => {
                    e.target.value = null;
                  }}
              />
            </div>
            <div
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
                className="w-full h-full cursor-pointer flex justify-center items-center"
            >
              {!hovered ? (
                  (isRecording && !isPaused) || (isRecording && isPaused) ? (
                      <svg
                          onClick={clickEvent}
                          width="100"
                          height="100"
                          viewBox="0 0 100 100"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                            d="M100 50C100 77.6142 77.6142 100 50 100C22.3858 100 0 77.6142 0 50C0 22.3858 22.3858 0 50 0C77.6142 0 100 22.3858 100 50Z"
                            fill="url(#paint0_linear_36_54)"
                            fillOpacity="0.3"
                        />
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M50 98C76.5097 98 98 76.5097 98 50C98 23.4903 76.5097 2 50 2C23.4903 2 2 23.4903 2 50C2 76.5097 23.4903 98 50 98ZM50 100C77.6142 100 100 77.6142 100 50C100 22.3858 77.6142 0 50 0C22.3858 0 0 22.3858 0 50C0 77.6142 22.3858 100 50 100Z"
                            fill="url(#paint1_linear_36_54)"
                        />
                        <path
                            d="M68.9989 46.1673V48.334C68.9881 53.1278 67.2168 57.7481 64.021 61.3201C60.8253 64.8924 56.4271 67.1647 51.6661 67.7037V78.6667H58.166C59.363 78.6667 60.3326 79.6362 60.3326 80.8333C60.3326 82.0304 59.363 83 58.166 83H40.8329C39.6358 83 38.6663 82.0304 38.6663 80.8333C38.6663 79.6362 39.6358 78.6667 40.8329 78.6667H47.3328V67.7037C42.5716 67.1647 38.1734 64.8924 34.9778 61.3201C31.782 57.7479 30.0108 53.1277 30 48.334V46.1673C30 44.9702 30.9695 44.0006 32.1666 44.0006C33.3637 44.0006 34.3333 44.9702 34.3333 46.1673V48.334C34.3333 53.7534 37.223 58.7583 41.9168 61.4692C46.6102 64.1775 52.3894 64.1775 57.0832 61.4692C61.7766 58.7581 64.6667 53.7531 64.6667 48.334V46.1673C64.6667 44.9702 65.6363 44.0006 66.8334 44.0006C68.0305 44.0006 69 44.9702 69 46.1673H68.9989ZM49.4992 59.1673C52.3727 59.1673 55.1271 58.0244 57.1584 55.9932C59.1896 53.9619 60.3324 51.2076 60.3324 48.3339V28.8339C60.3324 24.9637 58.2687 21.3861 54.9159 19.4524C51.563 17.5159 47.4356 17.5159 44.0827 19.4524C40.7299 21.3861 38.6662 24.9638 38.6662 28.8339V48.3339C38.6662 51.2074 39.809 53.9618 41.8403 55.9932C43.8715 58.0245 46.6256 59.1673 49.4992 59.1673Z"
                            fill="url(#paint2_linear_36_54)"
                        />
                        <defs>
                          <linearGradient
                              id="paint0_linear_36_54"
                              x1="-9.03162e-07"
                              y1="34.1837"
                              x2="100"
                              y2="76.5306"
                              gradientUnits="userSpaceOnUse"
                          >
                            <stop stopColor="#23D5BE"/>
                            <stop offset="1" stopColor="#835DF9"/>
                          </linearGradient>
                          <linearGradient
                              id="paint1_linear_36_54"
                              x1="-9.03162e-07"
                              y1="34.1837"
                              x2="100"
                              y2="76.5306"
                              gradientUnits="userSpaceOnUse"
                          >
                            <stop stopColor="#23D5BE"/>
                            <stop offset="1" stopColor="#835DF9"/>
                          </linearGradient>
                          <linearGradient
                              id="paint2_linear_36_54"
                              x1="-9.03162e-07"
                              y1="34.1837"
                              x2="100"
                              y2="76.5306"
                              gradientUnits="userSpaceOnUse"
                          >
                            <stop stopColor="#23D5BE"/>
                            <stop offset="1" stopColor="#835DF9"/>
                          </linearGradient>
                        </defs>
                      </svg>
                  ) : (
                      <svg
                          onClick={clickEvent}
                          width="100"
                          height="100"
                          viewBox="0 0 100 100"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M50 98C76.5097 98 98 76.5097 98 50C98 23.4903 76.5097 2 50 2C23.4903 2 2 23.4903 2 50C2 76.5097 23.4903 98 50 98ZM50 100C77.6142 100 100 77.6142 100 50C100 22.3858 77.6142 0 50 0C22.3858 0 0 22.3858 0 50C0 77.6142 22.3858 100 50 100Z"
                            fill="url(#paint0_linear_36_46)"
                        />
                        <path
                            d="M68.9989 46.1673V48.334C68.9881 53.1278 67.2168 57.7481 64.021 61.3201C60.8253 64.8924 56.4271 67.1647 51.6661 67.7037V78.6667H58.166C59.363 78.6667 60.3326 79.6362 60.3326 80.8333C60.3326 82.0304 59.363 83 58.166 83H40.8329C39.6358 83 38.6663 82.0304 38.6663 80.8333C38.6663 79.6362 39.6358 78.6667 40.8329 78.6667H47.3328V67.7037C42.5716 67.1647 38.1734 64.8924 34.9778 61.3201C31.782 57.7479 30.0108 53.1277 30 48.334V46.1673C30 44.9702 30.9695 44.0006 32.1666 44.0006C33.3637 44.0006 34.3333 44.9702 34.3333 46.1673V48.334C34.3333 53.7534 37.223 58.7583 41.9168 61.4692C46.6102 64.1775 52.3894 64.1775 57.0832 61.4692C61.7766 58.7581 64.6667 53.7531 64.6667 48.334V46.1673C64.6667 44.9702 65.6363 44.0006 66.8334 44.0006C68.0305 44.0006 69 44.9702 69 46.1673H68.9989ZM49.4992 59.1673C52.3727 59.1673 55.1271 58.0244 57.1584 55.9932C59.1896 53.9619 60.3324 51.2076 60.3324 48.3339V28.8339C60.3324 24.9637 58.2687 21.3861 54.9159 19.4524C51.563 17.5159 47.4356 17.5159 44.0827 19.4524C40.7299 21.3861 38.6662 24.9638 38.6662 28.8339V48.3339C38.6662 51.2074 39.809 53.9618 41.8403 55.9932C43.8715 58.0245 46.6256 59.1673 49.4992 59.1673Z"
                            fill="#F4F8FC"
                        />
                        <defs>
                          <linearGradient
                              id="paint0_linear_36_46"
                              x1="-9.03162e-07"
                              y1="34.1837"
                              x2="100"
                              y2="76.5306"
                              gradientUnits="userSpaceOnUse"
                          >
                            <stop stopColor="#23D5BE"/>
                            <stop offset="1" stopColor="#835DF9"/>
                          </linearGradient>
                        </defs>
                      </svg>
                  )
              ) : (
                  <svg
                      onClick={clickEvent}
                      width="100"
                      height="100"
                      viewBox="0 0 100 100"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                        d="M100 50C100 77.6142 77.6142 100 50 100C22.3858 100 0 77.6142 0 50C0 22.3858 22.3858 0 50 0C77.6142 0 100 22.3858 100 50Z"
                        fill="#F4F8FC"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M50 98C76.5097 98 98 76.5097 98 50C98 23.4903 76.5097 2 50 2C23.4903 2 2 23.4903 2 50C2 76.5097 23.4903 98 50 98ZM50 100C77.6142 100 100 77.6142 100 50C100 22.3858 77.6142 0 50 0C22.3858 0 0 22.3858 0 50C0 77.6142 22.3858 100 50 100Z"
                        fill="url(#paint0_linear_36_50)"
                    />
                    <path
                        d="M68.9989 46.1673V48.334C68.9881 53.1278 67.2168 57.7481 64.021 61.3201C60.8253 64.8924 56.4271 67.1647 51.6661 67.7037V78.6667H58.166C59.363 78.6667 60.3326 79.6362 60.3326 80.8333C60.3326 82.0304 59.363 83 58.166 83H40.8329C39.6358 83 38.6663 82.0304 38.6663 80.8333C38.6663 79.6362 39.6358 78.6667 40.8329 78.6667H47.3328V67.7037C42.5716 67.1647 38.1734 64.8924 34.9778 61.3201C31.782 57.7479 30.0108 53.1277 30 48.334V46.1673C30 44.9702 30.9695 44.0006 32.1666 44.0006C33.3637 44.0006 34.3333 44.9702 34.3333 46.1673V48.334C34.3333 53.7534 37.223 58.7583 41.9168 61.4692C46.6102 64.1775 52.3894 64.1775 57.0832 61.4692C61.7766 58.7581 64.6667 53.7531 64.6667 48.334V46.1673C64.6667 44.9702 65.6363 44.0006 66.8334 44.0006C68.0305 44.0006 69 44.9702 69 46.1673H68.9989ZM49.4992 59.1673C52.3727 59.1673 55.1271 58.0244 57.1584 55.9932C59.1896 53.9619 60.3324 51.2076 60.3324 48.3339V28.8339C60.3324 24.9637 58.2687 21.3861 54.9159 19.4524C51.563 17.5159 47.4356 17.5159 44.0827 19.4524C40.7299 21.3861 38.6662 24.9638 38.6662 28.8339V48.3339C38.6662 51.2074 39.809 53.9618 41.8403 55.9932C43.8715 58.0245 46.6256 59.1673 49.4992 59.1673Z"
                        fill="black"
                    />
                    <defs>
                      <linearGradient
                          id="paint0_linear_36_50"
                          x1="-9.03162e-07"
                          y1="34.1837"
                          x2="100"
                          y2="76.5306"
                          gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#23D5BE"/>
                        <stop offset="1" stopColor="#835DF9"/>
                      </linearGradient>
                    </defs>
                  </svg>
              )}
            </div>
            <div className="w-full h-full flex justify-center items-center">
              <svg
                  onClick={togglePauseResume}
                  className="cursor-pointer"
                  width="34"
                  height="32"
                  viewBox="0 0 34 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <path
                    d="M2.26958 8H5.61832C6.69723 8 7.57211 9.07891 7.57211 9.95379V30.0462C7.57211 31.1251 6.69726 32 5.61832 32H2.26958C1.19067 32 0.315796 30.9211 0.315796 30.0462V9.95379C0.315796 8.87488 1.19065 8 2.26958 8Z"
                    fill="#F4F8FC"
                />
                <path
                    d="M32.5535 21.9833L14.6793 31.6699C13.0001 32.5803 10.8158 31.5063 10.8158 29.6865V10.3134C10.8158 8.4946 13.0002 7.41946 14.6793 8.33001L32.5535 18.0176C33.3139 18.3854 33.7973 19.1562 33.7973 20.001C33.7973 20.8458 33.3139 21.6156 32.5535 21.9833Z"
                    fill="#F4F8FC"
                />
              </svg>
            </div>
          </div>
        </div>
        <div
            id="player"
            className="h-44 py-2 w-full flex flex-col justify-start items-center"
        >
          {Object.keys(props.tabs).map((tab) => (
              props.tabs[tab].blob ? (<Player key={tab} url={props.tabs[tab].blob} tab={props.tabs[tab].name}/>) : null
          ))}
        </div>
        <div
            id="reset-recordings"
          className="h-full flex justify-center items-end"
        >
          {(patientRecorded || actionRecorded) && (
            <div
              onClick={(e) => resetAll(e)}
              className="w-full cursor-pointer flex justify-center items-center text-mozn-text font-normal py-4 text-lg"
            >
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.1507 0C10.9152 0.00239442 10.7141 0.0862058 10.5457 0.25064L6.00326 4.80041L1.45431 0.25064C1.28988 0.086212 1.09114 0.00241118 0.855661 0C0.621001 0.00239442 0.419055 0.0862058 0.25064 0.25064C0.086212 0.419059 0.00241118 0.621001 0 0.855661C0.00239442 1.09112 0.0862059 1.28988 0.25064 1.45431L4.80041 6.00326L0.25064 10.5457C0.086212 10.7141 0.00241118 10.9152 0 11.1507C0.00239442 11.3854 0.0862059 11.5849 0.25064 11.7485C0.419059 11.9178 0.621001 12.0016 0.855661 12C1.09112 12.0016 1.28988 11.9178 1.45431 11.7485L6.00326 7.20613L10.5457 11.7485C10.7141 11.9178 10.9152 12.0016 11.1507 12C11.3854 12.0016 11.5849 11.9178 11.7485 11.7485C11.9178 11.5849 12.0016 11.3854 12 11.1507C12.0016 10.9152 11.9178 10.7141 11.7485 10.5457L7.20613 6.00326L11.7485 1.45431C11.9178 1.28988 12.0016 1.09114 12 0.855661C12.0016 0.621001 11.9178 0.419055 11.7485 0.25064C11.5849 0.086212 11.3854 0.00241118 11.1507 0Z"
                  fill="#C7CEDE"
                />
              </svg>
              <p className="ps-2">Reset All Recordings</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Recorder;
